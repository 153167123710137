import styles from "./About.module.css";
import profile from "../../assets/work/oscarMachineFloat.jpeg";
import ojProfile from "../../assets/work/ojSteps.jpeg";
import Modal from "./Modal"

function About() {
  return (
    <>
      <main className={styles.nonHomepage} id="about">
        <div className={styles.pageHeading}>
          <h1 className={styles.pageName}>Who We Are</h1>
          <h2 className={styles.pageSubname}>About Our Company</h2>
        </div>
        <section>
          <div className={styles.missionContainer}>
            <h1 className={styles.mission}>Our Mission</h1>
            <p className={styles.missionParagraph}>
              Oscar and Son's Construction is dedicated to exceeding our
              clients' expectations by delivering exceptional concrete solutions
              with the highest standards of craftsmanship and integrity. We
              strive to build enduring structures that not only stand the test
              of time but also enhance the aesthetic and functional qualities of
              the spaces we work on. Rooted in family values, community
              engagement, and a commitment to sustainability, we aim to leave a
              lasting positive impact on the communities we serve. Our mission
              is to shape a concrete legacy that reflects our dedication to
              excellence, innovation, and the enduring strength of family
              traditions.
            </p>
          </div>
        </section>
        <section className={styles.bioSection}>
          <div className={styles.bioHeader}>Meet the Owners</div>
          <div className={styles.profileImgContainer}>
            <img className={styles.profilePhoto} src={profile} alt="Profile" />
            <h3>Oscar Ibarra</h3>
            <Modal name={"Oscar Ibarra"}>
              <div className={styles.ownerBio}>
                <p>
                  Meet Oscar Ibarra Sr, the visionary founder and driving force
                  behind Oscar and Son's Construction, a beacon of excellence in
                  the concrete industry. With a career spanning over 35 years,
                  Oscar's journey is a testament to hard work, family values,
                  and an unwavering commitment to delivering top-notch
                  construction solutions.
                </p>

                <h4>Early Beginnings</h4>
                <p>
                  In the early 1980s, fresh out of school, Oscar Ibarra Sr
                  embarked on his career in concrete alongside his brothers.
                  Together, they laid the foundation for what would become a
                  lifelong passion and a thriving family business. Shoulder to
                  shoulder, they perfected their craft, pouring the groundwork
                  for Oscar's future endeavors.
                </p>
                <h4>The Birth of Oscar and Son's Construction</h4>
                <p>
                  In 2018, Oscar took the leap and founded Oscar and Son's
                  Construction, driven by a vision to create a company that not
                  only excelled in concrete work but also prioritized integrity
                  and client satisfaction. The early years were marked by hard
                  work, perseverance, and a commitment to exceeding client
                  expectations. Oscar's dedication to quality craftsmanship and
                  ethical business practices set his company apart in a
                  competitive industry.
                </p>

                <h4>Family Tradition</h4>
                <p>
                  Maintaining a deep commitment to family values, Oscar's son,
                  Oscar Ibarra Jr, joined the company. The father-son duo proved
                  to be a formidable team, blending Oscar Sr's wealth of
                  experience with Oscar Jr's fresh perspective and modern
                  construction techniques.
                </p>
                <h4>Legacy of Excellence</h4>
                <p>
                  Today, Oscar and Son's Construction stands as a testament to
                  Oscar Ibarra Sr's dedication to his craft and his unwavering
                  commitment to quality. The company's portfolio includes a
                  diverse range of residential and commercial projects, each
                  bearing the stamp of Oscar's passion for perfection. As he
                  works side by side with his son, Oscar Ibarra Jr, the legacy
                  of Oscar and Son's Construction is not just about concrete
                  structures; it's about the enduring strength of family, hard
                  work, and the pursuit of excellence that defines the Ibarra
                  name in the construction industry.
                </p>
              </div>
            </Modal>
          </div>
          <div className={styles.profileImgContainer}>
            <img
              className={styles.profilePhoto}
              src={ojProfile}
              alt="Profile"
            />
            <h3>Oscar Ibarra Jr</h3>
            <Modal name={"Oscar Ibarra Jr"}>
              <div className={styles.coOwnerBio}>
                <p>
                  Meet Oscar Ibarra Jr, a rising force in the construction
                  industry and the heart and soul of Oscar and Son's
                  Construction. From a young age, Oscar Jr. has been immersed in
                  the world of concrete, learning the ropes alongside his
                  father, Oscar Ibarra Sr. His journey is a testament to the
                  enduring legacy of craftsmanship and family values.
                </p>

                <h4>Apprenticeship Under Oscar Sr.</h4>
                <p>
                  Oscar Jr's formal apprenticeship began under the watchful eye
                  of his father, Oscar Ibarra Sr. From mixing the first batch of
                  concrete to mastering the art of precision in construction,
                  Oscar Jr. absorbed the invaluable lessons of the trade from
                  his father. Working side by side, the duo forged a bond that
                  extended beyond the construction site—it became a shared
                  passion for excellence.
                </p>
                <h4>Building on a Legacy:</h4>
                <p>
                  Today, Oscar Ibarra Jr stands at the forefront of Oscar and
                  Son's Construction, embodying the values of hard work,
                  integrity, and innovation passed down through generations. His
                  journey from a young apprentice to a key figure in the company
                  reflects not just a family legacy but a commitment to shaping
                  a concrete future—one that honors the past while embracing the
                  opportunities of tomorrow.
                </p>
              </div>
            </Modal>
          </div>
        </section>
      </main>
    </>
  );
}

export default About;
