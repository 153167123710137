import styles from "./ServiceArea.module.css";
import areaMap from "../../assets/areaMapMesa.gif";

const cities = [
  "Chandler",
  "Gilbert",
  "Tempe",
  "Scottsdale",
  "Guadalupe",
  "Fountain Hills",
  "Apache Junction",
  "Queen Creek",
  "Paradise Valley",
  "San Tan Valley",
  "Gold Canyon",
  "Phoenix",
];

const ServiceArea = () => {
  return (
    <main className={styles.nonHomepage}>
      <div className={styles.pageHeading}>
        <h1 className={styles.pageName}>Where we work</h1>
        <h2 className={styles.pageSubname}>Service Area</h2>
      </div>
      <section className={styles.serviceAreaSection}>
        <div className={styles.inServiceArea}>
          <img src={areaMap} alt="mesa-map" />
          <div>
            <h3>Areas We Serve</h3>
            <p>
              We provide our top-notch services in Mesa, AZ and surrounding
              areas:
            </p>

            <ul className={styles.cityList}>
              {cities.map((city, index) => (
                <li key={index}>{city}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.outsideServiceArea}>
          <p>
            If your area is not listed, please call us at{" "}
            <a href="tel:520-485-9450">520-485-9450</a> or fill out the{" "}
            <a href="/contact">contact form</a>.
          </p>
        </div>
      </section>
    </main>
  );
};

export default ServiceArea;
