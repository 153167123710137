import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home-page/Home";
import About from "./pages/about-page/About";
import Contact from "./pages/contact-page/Contact";
import Services from "./pages/services-page/Services";
import Footer from "./headerAndFooter/Footer";
import Header from "./headerAndFooter/Header";
import SmallHeader from "./headerAndFooter/SmallHeader";
import ServiceArea from "./pages/service-area/ServiceArea";

const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  CONTACT: "/contact",
  SERVICEAREA: "/service-area",
};

function AppContent() {
  const location = useLocation();
  const showSmallHeader = [
    ROUTES.ABOUT,
    ROUTES.SERVICES,
    ROUTES.CONTACT,
    ROUTES.SERVICEAREA,
  ].some((path) => location.pathname.includes(path));

  return (
    <div className="App">
      {showSmallHeader ? <SmallHeader /> : <Header />}
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.SERVICES} element={<Services />} />
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.SERVICEAREA} element={<ServiceArea />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
