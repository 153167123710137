import styles from "./Home.module.css";
import AboutSection from "./AboutSection";
import ServiceSection from "./ServiceSection";
import ContactSection from "./ContactSection";

function Home() {
  return (
    <>
      <main className={styles.homepage}>
        <section className={styles.intro}>
          <h1 className={styles.welcome}>
            Welcome to Oscar & Son Construction!
          </h1>
          <p>
          We proudly serve <span className={styles.serviceArea}>Mesa, AZ and the surrounding areas</span>. We take immense
            pride in our craftsmanship and commitment to honesty. Join us on a
            journey where concrete becomes more than just a construction
            material; it becomes a testament to our dedication to quality and
            ethics. Discover how we transform your concrete dreams into reality,
            one pour at a time
          </p>
        </section>
        <ServiceSection />
        <AboutSection />
        <ContactSection />
      </main>
    </>
  );
}

export default Home;
