import styles from "./Button.module.css";

const Button = ({ href, children, type = "button", onClick, ...props }) => {
  if (href) {
    return (
      <div className={styles.buttonContainer}>
        <a href={href} className={styles.button} {...props}>
          {children}
        </a>
      </div>
    );
  }
  return (
    <div className={styles.buttonContainer}>
      <button
        type={type}
        onClick={onClick}
        className={styles.button}
        {...props}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
