import Button from "../../components/Button";
import styles from "./Home.module.css";

function ContactSection() {
  return (
    <section className={styles.contactSection}>
    <div className={styles.contactText}>
      Interested in getting more info or want to request an estimate?
    </div>
   <Button href="/contact" >Contact Us</Button>
    <div></div>
  </section>
  )
}

export default ContactSection