import ReactModal from "react-modal";
import { useState } from "react";
import styles from "./About.module.css";
import Button from "../../components/Button";

const Modal = ({ children, name }) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#A20808";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={styles.modalContainer}>
      <Button onClick={openModal}>More Details</Button>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Information on Oscar Ibarra Sr"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
      >
        <button className={styles.xIcon} onClick={closeModal}>
          X
        </button>
        <h2
          className={styles.modalHeader}
          ref={(_subtitle) => (subtitle = _subtitle)}
        >
          About {name}
        </h2>
        <div>{children}</div>
      </ReactModal>
    </div>
  );
};
export default Modal;
