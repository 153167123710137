import styles from "./Home.module.css";
import City from "../../assets/icons/city-outline.svg";
import House from "../../assets/icons/house-outline.svg";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

function ServiceSection() {
  return (
    <section className={styles.servicesSection}>
      <div className={styles.sectionTitle}>Our Services</div>
      <div className={styles.card1}>
        <div className={styles.contentContainer}>
          <img className={styles.cardIcon} src={City} alt="building icon" />
          <h3>Commerical Services</h3>
          <Button href="/services/#commercial">More details</Button>
        </div>
      </div>
      <div className={styles.card2}>
        <div className={styles.contentContainer}>
          <img className={styles.cardIcon} src={House} alt="house icon" />
          <h3>Residential Services</h3>
          <Button href="/services/#residential">More details</Button>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
