import logo from "../assets/logoRed.png";
import fbIcon from "../assets/square-facebook.svg";
import emailIcon from "../assets/email.svg";
import phone from "../assets/phone-solid.svg";
import styles from "./Header.module.css";
import { useEffect, useState } from "react";
import HamburgerMenu from "./HamburgerMenu";

export const iconArray = [
  {
    link: "https://m.facebook.com/p/Oscar-and-Son-Ibarra-Contracting-100043690465965/",
    src: fbIcon,
    className: styles.fbIcon,
    alt: "facebook icon",
  },
  {
    link: "mailto:oscaribarraconcreteco@gmail.com",
    src: emailIcon,
    className: styles.emailIcon,
    alt: "email icon",
  },
  {
    link: "tel:520-485-9450",
    src: phone,
    className: styles.phone,
    alt: "phone icon",
  },
];

export const navigation = [
  { link: "/", text: "Home", className: styles.homeLink },
  { link: "/services", text: "What We Do", className: styles.serviceLink },
  { link: "/about", text: "Who We Are", className: styles.aboutLink },
  { link: "/contact", text: "Contact", className: styles.contactLink },
  {
    link: "/service-area",
    text: "Service Area",
    className: styles.serviceAreaLink,
  },
];

export function Header() {
  const [windowWidth, setWindowWidth] = useState();
  const lastIndex = navigation.length - 1;

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [windowWidth, setWindowWidth]);

  return (
    <header className={styles.appHeader}>
      <div className={styles.imgContainer}>
        <a className={styles.logoLink} href="/">
          <img src={logo} className={styles.logo} alt="logo" />
        </a>
        <div className={styles.slogan}>
          "Professional work done by people with Integrity"
        </div>
      </div>
      <nav className={styles.linksContainer}>
        {navigation.map((nav, index) => (
          <>
            <li key={nav.text}>
              <a href={nav.link} className={nav.className}>
                {nav.text}
              </a>
            </li>

            {index !== lastIndex && <hr className={styles.navLine} />}
          </>
        ))}
      </nav>
      <nav className={styles.HamburgerMenuContainer}>
        <HamburgerMenu />
      </nav>
      <ul className={styles.icons}>
        {iconArray.map((icon) => (
          <li key={icon.src}>
            <div>
              <a href={icon.link} target="_blank" rel="noreferrer">
                <img className={icon.className} src={icon.src} alt={icon.alt} />
              </a>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.licensedContainer}>
        <img
          className={styles.licensedIcon}
          src="https://t3.ftcdn.net/jpg/05/48/39/48/360_F_548394813_K0P0WogUsY8T92JWeAMHMxvB3fqCJ6xh.png"
          alt=""
        />
      </div>
    </header>
  );
}

export default Header;
