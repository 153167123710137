import styles from "./Contact.module.css";
import tractor from "../../assets/work/tractor.jpeg";
import { useState, useEffect } from "react";
import DOMPurify from 'dompurify';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    description: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const sanitizeInput = (input) => DOMPurify.sanitize(input);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: sanitizeInput(value),
    }));
  };

  useEffect(() => {
    const isValid =
      formData.firstName.trim() &&
      formData.lastName.trim() &&
      formData.phone.trim() &&
      formData.email.trim() &&
      formData.description.trim();
    setIsFormValid(isValid);
  }, [formData]);

  return (
    <>
      <main className={styles.nonHomepage}>
        <div className={styles.pageHeading}>
          <h1 className={styles.pageName}>Contact Us</h1>
          <h2 className={styles.pageSubname}>Request information</h2>
        </div>
        <section className={styles.formSection}>
          <p>*All fields are required</p>
          <form
            className={styles.form}
            name="contact v2"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact v2" />
            <div hidden>
              <input name="bot-field" />
            </div>

            <div>
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="phone">Phone Number*</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="description">Description*</label>
              <textarea
                name="description"
                rows="5"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                disabled={!isFormValid}
                className={isFormValid ? styles.submit : styles.disabledSubmit}
              >
                Submit
              </button>
            </div>
          </form>
          <div className={styles.imgContainer}>
            <img src={tractor} alt="tractor" />
          </div>
        </section>
      </main>
    </>
  );
}

export default Contact;
