import logo from "../assets/logoRed.png";
import styles from "./SmallHeader.module.css";
import HamburgerMenu from "./HamburgerMenu";
import { navigation, iconArray } from "./Header";

export function SmallHeader() {
  const lastIndex = navigation.length - 1;
  return (
    <header className={styles.appHeader}>
      <div className={styles.imgContainer}>
        <a className={styles.logoLink} href="/">
          <img src={logo} className={styles.logo} alt="logo" />
        </a>
      </div>
      <nav className={styles.linksContainer}>
        {navigation.map((nav, index) => (
          <>
            <li key={nav.text}>
              <a href={nav.link} className={nav.className}>
                {nav.text}
              </a>
            </li>
            {index !== lastIndex && <hr className={styles.navLine} />}
          </>
        ))}
      </nav>
      <ul className={styles.icons}>
        {iconArray.map((icon) => (
          <li key={icon.src}>
            <div>
              <a href={icon.link} target="_blank" rel="noreferrer">
                <img className={icon.className} src={icon.src} alt={icon.alt} />
              </a>
            </div>
          </li>
        ))}
      </ul>
      <nav className={styles.HamburgerMenuContainer}>
        <HamburgerMenu />
      </nav>
    </header>
  );
}

export default SmallHeader;
